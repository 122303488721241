import "./style.css";
import i1 from "./img/1.svg";
import i2 from "./img/2.svg";
import i3 from "./img/3.svg";
export default function Nav() {
  return (
    <div className="navic">
      <p>Наши соц. сети:</p>
      <div>
        <a href="https://t.me/C_R_Turkey" target="_blank">
          <img src={i1} alt="" />
        </a>
        <a href="https://www.youtube.com/@cr.property" target="_blank">
          <img src={i2} alt="" />
        </a>
        <a href="https://www.instagram.com/c.r.property_" target="_blank">
          <img src={i3} alt="" />
        </a>
      </div>
    </div>
  );
}

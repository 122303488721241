export default function Otkaz() {
  return (
    <div className="otkaz">
      <h4>Отказ от ответственности</h4>
      <br />
      <br />{" "}
      <p>
        В соответствии с действующим законодательством Российской Федерации,
        Администрация отказывается от каких-либо заверений и гарантий,
        предоставление которых может иным образом подразумеваться, и
        отказывается от ответственности в отношении Сайта, Содержимого и их
        использования. <br />
        <br /> Ни при каких обстоятельствах Администрация Сайта не будет нести
        ответственности ни перед какой стороной за какой-либо прямой, непрямой,
        особый или иной косвенный ущерб в результате любого использования
        информации на этом Сайте или на любом другом сайте, на который имеется
        гиперссылка с нашего cайта, возникновение зависимости, снижения
        продуктивности, увольнения или прерывания трудовой активности, а равно и
        отчисления из учебных учреждений, за любую упущенную выгоду,
        приостановку хозяйственной деятельности, потерю программ или данных в
        Ваших информационных системах или иным образом, возникшие в связи с
        доступом, использованием или невозможностью использования Сайта,
        Содержимого или какого-либо связанного интернет-сайта, или
        неработоспособностью, ошибкой, упущением, перебоем, дефектом, простоем в
        работе или задержкой в передаче, компьютерным вирусом или системным
        сбоем, даже если администрация будет явно поставлена в известность о
        возможности такого ущерба. <br />
        <br /> Пользователь соглашается с тем, что все возможные споры будут
        разрешаться по нормам российского права. <br />
        <br /> Пользователь соглашается с тем, что нормы и законы о защите прав
        потребителей не могут быть применимы к использованию им Сайта, поскольку
        он не оказывает возмездных услуг. <br />
        <br /> Используя данный Сайт, Вы выражаете свое согласие с «Отказом от
        ответственности» и установленными Правилами и принимаете всю
        ответственность, которая может быть на Вас возложена.
      </p>
    </div>
  );
}

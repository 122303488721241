import "./style.css";
import ic1 from "./img/1.svg";
import ic2 from "./img/2.svg";
import ic3 from "./img/3.svg";
import ic4 from "./img/4.svg";
import ic5 from "./img/5.svg";
import small1 from "./img/small1.webp";
import small2 from "./img/small2.webp";
import Fade from "react-reveal/Fade";
import rig from "./img/rig.webp";
import palmD from "./img/palmDown.webp";
import Zoom from "react-reveal/Zoom";
import mob1 from "./img/mob1.webp";
import mob2 from "./img/mob2.webp";
import mob3 from "./img/mob3.webp";
export default function Infr({ form, setForm }) {
  return (
    <div className="Infr">
      <div className="fullInstructure">
        <div className="blocc">
          <img src={ic1} alt="" />
          <p>рядом с вами</p>
          <span>
            Детская площадка / Школы / <br /> Больница / Кафе и рестораны
          </span>
        </div>
        <div className="blocc">
          <img src={ic2} alt="" />
          <p>парковка</p>
          <span>Подземная</span>
        </div>
        <h3>
          Полная <br /> инфраструктура:
        </h3>
      </div>
      <div className="InfrContent">
        <div className="leftInfr">
          <div className="bl">
            <div className="blocc">
              <img src={ic3} alt="" />
              <p>бассейны</p>
              <span>
                Открытый / Закрытый / <br /> Детские
              </span>
            </div>
            <div className="blocc">
              <img src={ic4} alt="" />
              <p>развлечения</p>
              <span>
                Kинотеатр / <br /> Барбекю
              </span>
            </div>
            <div className="blocc">
              <img src={ic5} alt="" />
              <p>отдых</p>
              <span>
                SPA / Фитнес / Теннисный <br /> корт / Мини-гольф
              </span>
            </div>
          </div>
          {document.body.clientWidth < 640 && (
            <div className="mobsik">
              <img src={mob1} alt="" />
              <div>
                <img src={mob2} alt="" />
                <img src={mob3} alt="" />
              </div>
            </div>
          )}
          <div className="smallic">
            <Fade bottom delay={200}>
              <img src={small1} className="sm1" alt="" />
            </Fade>
            <Fade bottom delay={100}>
              <img src={small2} className="sm2" alt="" />
            </Fade>
          </div>
        </div>
        <div className="rightInfr">
          <Fade bottom>
            <img src={rig} className="rigi" alt="" />{" "}
          </Fade>
          <img src={palmD} className="palmD" alt="" />
        </div>
      </div>
      <Zoom>
        <div className="pr" onClick={() => setForm(true)}>
          Получить предложение
        </div>
      </Zoom>
    </div>
  );
}

import "./style.css";
import gal from "./img/gal.svg";
import { useState } from "react";
export default function Question() {
  const [q1, setQ1] = useState(1);

  return (
    <div className="question" id="section7">
      <h3>
        Часто задаваемые <br /> вопросы
      </h3>
      <div className="questEm">
        <div className="vibi" onClick={() => setQ1(1)}>
          <div className="polo">
            <div>▶</div>
            <p>
              Какие документы мне понадобятся для покупки недвижимости в Турции?
            </p>
            <img
              src={gal}
              alt=""
              style={q1 == 1 ? { transform: "rotate(180deg)" } : {}}
            />
          </div>
          <div
            className="hiden"
            style={
              q1 == 1 && document.body.clientWidth > 640
                ? { display: "block", height: "10vw" }
                : document.body.clientWidth < 640 && q1 == 1
                ? { display: "block", height: "45vw" }
                : {}
            }
          >
            Для приобретения недвижимости в Турции иностранному гражданину
            потребуются заграничный паспорт и налоговый номер (vergi numarası).
            Также, необходимо заключить официальный договор купли-продажи с
            продавцом. Мы предоставляем свою помощь на каждом этапе данного
            процесса.
          </div>
        </div>
        <div className="vibi" onClick={() => setQ1(2)}>
          <div className="polo">
            <div>▶</div>
            <p>
              Я планирую дистанционно купить квартиру и сдавать ее в аренду.
              Сможете ли вы взять на себя всю организацию процесса аренды,
              включая оформление договоров и содержание жилья?
            </p>
            <img
              src={gal}
              alt=""
              style={q1 == 2 ? { transform: "rotate(180deg)" } : {}}
            />
          </div>

          <div
            className="hiden"
            style={
              q1 == 2 && document.body.clientWidth > 640
                ? { display: "block", height: "10vw" }
                : document.body.clientWidth < 640 && q1 == 2
                ? { display: "block", height: "50vw" }
                : {}
            }
          >
            {" "}
            Да, конечно. Процедура выглядит следующим образом: Мы заключаем
            договор с владельцем жилья, затем берем на себя все этапы, связанные
            со сдачей квартиры в аренду. В договоре прописывается сумма прибыли
            за сутки и за месяц аренды, которая в дальнейшем будет переводиться
            по банковским реквизитам собственнику недвижимости. <br /> <br />
            Будем рады проконсультировать вас более детально, а также ответить
            на ваши вопросы.
          </div>
        </div>
        <div className="vibi" onClick={() => setQ1(3)}>
          <div className="polo">
            <div>▶</div>
            <p>Помогаете ли вы с получением ВНЖ?</p>
            <img
              src={gal}
              alt=""
              style={q1 == 3 ? { transform: "rotate(180deg)" } : {}}
            />
          </div>

          <div
            className="hiden"
            style={
              q1 == 3 && document.body.clientWidth > 640
                ? { display: "block", height: "10vw" }
                : document.body.clientWidth < 640 && q1 == 3
                ? { display: "block", height: "50vw" }
                : {}
            }
          >
            Да, мы обеспечиваем полное сопровождение клиентов в этом процессе и
            предоставляем иностранным клиентам полную юридическую поддержку.
            Наши менеджеры помогут вам с подготовкой пакета документов, подадут
            заявку в соответствующий орган регистрации и будут управлять всеми
            последующими этапами процесса.
          </div>
        </div>
        <div className="vibi" onClick={() => setQ1(4)}>
          <div className="polo">
            <div>▶</div>
            <p>Помогаете ли вы с получением гражданства?</p>
            <img
              src={gal}
              alt=""
              style={q1 == 4 ? { transform: "rotate(180deg)" } : {}}
            />
          </div>

          <div
            className="hiden"
            style={
              q1 == 4 && document.body.clientWidth > 640
                ? { display: "block", height: "10vw" }
                : document.body.clientWidth < 640 && q1 == 4
                ? { display: "block", height: "50vw" }
                : {}
            }
          >
            Да, мы обеспечиваем полное сопровождение клиентов в этом процессе и
            предоставляем иностранным клиентам полную юридическую поддержку.
            Наши менеджеры помогут вам с подготовкой пакета документов, подадут
            заявку в соответствующий орган регистрации и будут управлять всеми
            последующими этапами процесса.
          </div>
        </div>
        <div className="vibi" onClick={() => setQ1(5)}>
          <div className="polo">
            <div>▶</div>
            <p>Я планирую переезд со своим ребенком. Получит ли он ВНЖ?</p>
            <img
              src={gal}
              alt=""
              style={q1 == 5 ? { transform: "rotate(180deg)" } : {}}
            />
          </div>

          <div
            className="hiden"
            style={
              q1 == 5 && document.body.clientWidth > 640
                ? { display: "block", height: "10vw" }
                : document.body.clientWidth < 640 && q1 == 5
                ? { display: "block", height: "70vw" }
                : {}
            }
          >
            Дети, возраст которых не достиг 18 лет, могут получить вид на
            жительство на основании TAPU на недвижимость одного из родителей.
            Если Ваш ребенок совершеннолетний, он может получить вид на
            жительство в Турции на общих основаниях, как ближайший родственник
            владельца недвижимости. Второй вариант оформления ВНЖ — вписать
            ребенка в ТАПУ, как еще одного собственника турецкого жилья. Вся
            процедура не представляет сложности и делается в кратчайшие сроки,
            мы сопровождаем наших клиентов на протяжении всего этого процесса.
          </div>
        </div>
        <div className="vibi" onClick={() => setQ1(6)}>
          <div className="polo">
            <div>▶</div>
            <p>Есть ли в Турции оформление ипотеки для иностранных граждан?</p>
            <img
              src={gal}
              alt=""
              style={q1 == 6 ? { transform: "rotate(180deg)" } : {}}
            />
          </div>

          <div
            className="hiden"
            style={
              q1 == 6 && document.body.clientWidth > 640
                ? { display: "block", height: "10vw" }
                : document.body.clientWidth < 640 && q1 == 6
                ? { display: "block", height: "40vw" }
                : {}
            }
          >
            Да, иностранные граждане могут получить ипотеку в Турции, но условия
            и требования могут отличаться от тех, которые действуют для граждан
            Турции.
          </div>
        </div>
      </div>
    </div>
  );
}

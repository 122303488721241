export default function Agreement() {
  return (
    <div className="otkaz">
      <h4>Согласие с рассылкой</h4>
      <br />
      <br />
      <p>
        Заполняя форму на нашем сайте — вы соглашаетесь с нашей политикой
        конфиденциальности. Также вы соглашаетесь с тем, что мы имеем право
        разглашать ваши личные данные в следующих случаях <br />
        <br /> 1) С Вашего согласия: Во всех остальных случаях перед передачей
        информации о Вас третьим сторонам наша Компания обязуется получить Ваше
        явное согласие. Например, наша Компания может реализовывать совместное
        предложение или конкурс с третьей стороной, тогда мы попросим у Вас
        разрешение на совместное использование Вашей личной информации с третьей
        стороной <br />
        <br /> 2) Компаниям, работающим от нашего лица: Мы сотрудничаем с
        другими компаниями, выполняющими от нашего лица функции бизнес
        поддержки, в связи с чем Ваша личная информация может быть частично
        раскрыта. Мы требуем, чтобы такие компании использовали информацию
        только в целях предоставления услуг по договору; им запрещается
        передавать данную информацию другим сторонам в ситуациях, отличных от
        случаев, когда это вызвано необходимостью предоставления оговоренных
        услуг. Примеры функций бизнес поддержки: выполнение заказов, реализация
        заявок, выдача призов и бонусов, проведение опросов среди клиентов и
        управление информационными системами. Мы также раскрываем обобщенную
        неперсонифицированную информацию при выборе поставщиков услуг. <br />
        <br /> 3) Дочерним и совместным предприятиям: Под дочерним или
        совместным предприятием понимается организация, не менее 50% долевого
        участия которой принадлежит Компании. При передаче Вашей информации
        партнеру по дочернему или совместному предприятию наша Компания требует
        не разглашать данную информацию другим сторонам в маркетинговых целях и
        не использовать Вашу информацию каким-либо путем, противоречащим Вашему
        выбору. Если Вы указали, что не хотите получать от нашей Компании
        какие-либо маркетинговые материалы, то мы не будем передавать Вашу
        информацию своим партнерам по дочерним и совместным предприятиям для
        маркетинговых целей. <br />
        <br /> 4) На совместно позиционируемых или партнерских страницах: Наша
        Компания может делиться информацией с компаниями-партнерами, вместе с
        которыми реализует специальные предложения и мероприятия по продвижению
        товара на совместно позиционируемых страницах нашего сайта. При запросе
        анкетных данных на таких страницах Вы получите предупреждение о передаче
        информации. Партнер использует любую предоставленную Вами информацию
        согласно собственному уведомлению о конфиденциальности, с которым Вы
        можете ознакомиться перед предоставлением информации о себе. <br />
        <br /> 5) При передаче контроля над предприятием: Наша Компания
        оставляет за собой право передавать Ваши анкетные данные в связи с
        полной или частичной продажей или трансфертом нашего предприятия или его
        активов. При продаже или трансферте бизнеса наша Компания предоставит
        Вам возможность отказаться от передачи информации о себе. В некоторых
        случаях это может означать, что новая организация не сможет далее
        предоставлять Вам услуги или продукты, ранее предоставляемые нашей
        Компанией. <br />
        <br /> 6) Правоохранительным органам: Наша Компания может без Вашего на
        то согласия раскрывать персональную информацию третьим сторонам по любой
        из следующих причин: во избежание нарушений закона, нормативных правовых
        актов или постановлений суда; участие в правительственных
        расследованиях; помощь в предотвращении мошенничества; а также
        укрепление или защита прав Компании или ее дочерних предприятий. Вся
        личная информация, которая передана Вами для регистрации на нашем сайте,
        может быть в любой момент изменена либо полностью удалена из нашей базы
        по Вашему запросу. Для этого Вам необходимо связаться с нами любым
        удобным для Вас способом, использую контактную информацию, размещенную в
        специальном разделе нашего сайта. Если Вы захотите отказаться от
        получения писем нашей регулярной рассылки, вы можете это сделать в любой
        момент с помощью специальной ссылки, которая размещается в конце каждого
        письма.
      </p>
    </div>
  );
}

import { useEffect } from "react";
import af from "../ec.svg";
import { Zoom } from "react-reveal";
export default function Thanks() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="thanks">
        <h4>Поздравляем!</h4>

        <img src={af} alt="" />

        <p>
          <b> В самое ближайшее время с вами свяжется</b>
          <br /> наш специалист и даст подробные ответы на все вопросы
        </p>
      </div>
    </>
  );
}

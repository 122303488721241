import "./style.css";
import ic1 from "./img/1.svg";
import ic2 from "./img/2.svg";
import ic3 from "./img/3.svg";
import small from "./img/small.png";
import big from "./img/big.webp";
import palmL from "./img/palmL.png";
import palmR from "./img/palmR.webp";
import Fade from "react-reveal/Fade";
import Mob from "./img/mob.webp";

import { useNavigate } from "react-router-dom";
import { Slider } from "infinite-react-carousel";
export default function Live({ form, setForm }) {
  const Navigate = useNavigate();
  return (
    <div className="Live">
      <img src={palmL} className="palmL" alt="" />
      <img src={palmR} className="palmR" alt="" />
      <div className="liveLeft">
        <h2>
          жилой <br /> комплекс <br />в аланье
        </h2>
        <img src={Mob} className="LiveMob" alt="" />
        <ul>
          <Fade left cascade>
            <li>
              <img src={ic1} alt="" />
              <div>
                <p>Территория ЖК:</p>
                <span>
                  11000 м2, зеленая, <br />с ландшафтным дизайном
                </span>
              </div>
            </li>
            <li>
              <img src={ic2} alt="" />
              <div>
                <p>19.5 млн рублей</p>
                <span>стоимость</span>
              </div>
            </li>
            <li>
              <img src={ic3} alt="" />
              <div>
                <p>4 этаж, 113 м2, 2 балкона</p>
                <span>площадь</span>
              </div>
            </li>
          </Fade>
        </ul>
      </div>
      <div className="liveRight">
        <Fade left>
          <img src={small} className="small" alt="" />
        </Fade>

        <Fade right>
          <img src={big} className="big" alt="" />
        </Fade>
      </div>
    </div>
  );
}

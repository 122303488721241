import './App.css';

import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

import Agreement from './Pages/Agreement';
import { Fade } from 'react-reveal';
import Footer from './Components/13footer';
import InputMask from 'react-input-mask';
import Landing from './Pages/Landing';
import Obj1 from './Components/objects/1obj';
import Obj2 from './Components/objects/2obj';
import Obj3 from './Components/objects/3obj';
import Obj4 from './Components/objects/4obj';
import Obj5 from './Components/objects/5obj';
import Obj6 from './Components/objects/6obj';
import Obj7 from './Components/objects/7obj';
import Obj8 from './Components/objects/8obj';
import Otkaz from './Pages/Otkaz';
import Privacy from './Pages/Privacy';
import Thanks from './Pages/Thanks';
import close from './close.png';
import closet from './img/close.svg';
import f1 from './img/1.webp';
import logo from './logo.svg';
import ns from './img/1496.gif';
import pal from './img/palm.png';
import titl from './img/title.svg';

function App() {
  const location = useLocation();
  const [error1, setError1] = useState(false);
  const [error2, setError2] = useState(false);
  const [form, setForm] = useState(false);
  const [pops, setPops] = useState(false);
  const [pop, setPop] = useState(false);
  const [loading, isLoading] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      document.getElementsByClassName('preloader')[0].style.transform = 'translateY(-1200px)';
    }, 2300);
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const Navigate = useNavigate();
  const [fio, setFio] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  //15 sec

  const [tel, setTel] = useState('');
  const [name, setName] = useState('');

  function sendMessage() {
    if (phone.length < 10 || fio.length < 2) {
      setError1(true);
    } else {
      isLoading(true);

      let form = new FormData();
      form.append('name', fio);
      form.append('tel', phone);
      let quiz = '';
      if (localStorage.getItem('answers')) {
        {
          quiz = localStorage.getItem('answers');
        }
      }
      form.append('quiz', quiz);
      fetch('https://crproperty-app.ru/amocrm/webhook.php', {
        method: 'POST',
        body: JSON.stringify({
          phone: phone,
          url_main: 'https://turkey-estate.pro',
          name: fio,
          answerQuiz: quiz,
          announcementUrl: document.location.href,
        }),
      }).then(response => {
        fetch('https://turkey-estate.pro/bot.php', {
          method: 'POST',
          body: form,
        }).then(response => {
          setFio('');
          isLoading(false);
          setEmail('');
          setPhone('');
          setForm(false);
          localStorage.setItem('answers', '');
          Navigate('/thanks');
        });
      });
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setPops(true);
    }, 30000);
  }, []);
  useEffect(() => {
    if (tel.length >= 10) {
      setError2(false);
    }
  }, [tel]);
  useEffect(() => {
    if (phone.length >= 10) {
      setError1(false);
    }
  }, [phone]);
  function sendMessagePop() {
    if (tel.length < 10 || name.length < 2) {
      setError2(true);
    } else {
      isLoading(true);
      let form = new FormData();
      form.append('name', name);
      form.append('tel', tel);

      let value1 = 'Хит продаж; ' + document.querySelector('input[name="sposob"]:checked').value;
      form.append('quiz', value1);
      fetch('https://crproperty-app.ru/amocrm/webhook.php', {
        method: 'POST',
        body: JSON.stringify({
          phone: tel,
          url_main: 'https://turkey-estate.pro',
          name: name,
          answerQuiz: value1,
          announcementUrl: document.location.href,
        }),
      }).then(response => {
        fetch('https://turkey-estate.pro/bot.php', {
          method: 'POST',
          body: form,
        }).then(response => {
          setName('');
          setPop(false);
          setTel('');
          isLoading(false);
          setPops(false);
          Navigate('/thanks');
        });
      });
    }
  }
  /*   window.addEventListener("DOMContentLoaded", function masks() {
    [].forEach.call(document.querySelectorAll(".tele"), function (input) {
      var keyCode;
      function mask(event) {
        event.keyCode && (keyCode = event.keyCode);
        var pos = this.selectionStart;
        if (pos < 3) event.preventDefault();
        var matrix = "+7 (___) ___ ____",
          i = 0,
          def = matrix.replace(/\D/g, ""),
          val = this.value.replace(/\D/g, ""),
          new_value = matrix.replace(/[_\d]/g, function (a) {
            return i < val.length ? val.charAt(i++) || def.charAt(i) : a;
          });
        i = new_value.indexOf("_");
        if (i != -1) {
          i < 5 && (i = 3);
          new_value = new_value.slice(0, i);
        }
        var reg = matrix
          .substr(0, this.value.length)
          .replace(/_+/g, function (a) {
            return "\\d{1," + a.length + "}";
          })
          .replace(/[+()]/g, "\\$&");
        reg = new RegExp("^" + reg + "$");
        if (
          !reg.test(this.value) ||
          this.value.length < 5 ||
          (keyCode > 47 && keyCode < 58)
        )
          setPhone(new_value);
        if (event.type == "blur" && this.value.length < 5) this.value = "";
      }

      input.addEventListener("input", mask, false);
      input.addEventListener("focus", mask, false);
      input.addEventListener("blur", mask, false);
      input.addEventListener("keydown", mask, false);
    });
  }); */
  return (
    <div className='App'>
      <div class='preloader'>
        <img class='vec' src={logo} />
      </div>
      {/* {pops && location.pathname == '/' && localStorage.pop != 'no' && (
        <div className='timerPop'>
          <div className='timerPopBody'>
            {loading && <span class='loader'></span>}
            <img
              src={closet}
              className='closly'
              onClick={() => {
                localStorage.setItem('pop', 'no');
                setPops(false);
              }}
            />
            <img src={f1} className='xit' />
            <img src={titl} className='tito' />
            {error2 && <p className='ero'>Заполните все поля</p>}
            <input type='text' placeholder='Ваше имя' value={name} onChange={e => setName(e.target.value)}></input>
            <InputMask mask='+9 999 999 99 999999' className='tele' maskChar={null} value={tel} onChange={e => setTel(e.target.value)} placeholder='+7 999 888 88 88*' />
            <div className='joker'>
              <div>
                <input checked type='radio' id='lb1' value='Позвонить' name='sposob'></input>
                <label htmlFor='lb1'>ПОЗВОНИТЬ</label>
              </div>
              <div>
                <input type='radio' value='Написать' id='lb2' name='sposob'></input>
                <label htmlFor='lb2'>НАПИСАТЬ</label>
              </div>
            </div>
            <button className='button' onClick={() => sendMessagePop()} style={loading ? { pointerEvents: 'none' } : {}}>
              ПОЛУЧИТЬ ПРЕДЛОЖЕНИЕ
            </button>
          </div>
        </div>
      )} */}
      {form && (
        <div className='form' id='check' onClick={e => e.target.id == 'check' && setForm(false)}>
          <div className='formBody' id='form'>
            {loading && <span class='loader'></span>}
            <img src={close} onClick={() => setForm(false)} />
            <h3>
              Заполните <br></br> форму
            </h3>
            {error1 && <p className='ero'>Заполните все поля</p>}
            <input type='text' value={fio} onChange={e => setFio(e.target.value)} placeholder='Ваше имя'></input>
            <InputMask
              mask='+9 999 999 99 999999'
              className='tele'
              maskChar={null}
              value={phone}
              onChange={e => setPhone(e.target.value)}
              placeholder='+7 999 888 88 88*'
            />

            <div onClick={() => sendMessage()} style={loading ? { pointerEvents: 'none' } : {}}>
              отправить
            </div>
          </div>
        </div>
      )}

      <Routes>
        <Route path='/' element={<Landing form={form} setForm={setForm} pop={pop} setPop={setPop} />}></Route>
        <Route path='/thanks' element={<Thanks />}></Route>
        <Route path='/obj1' element={<Obj1 form={form} setForm={setForm} pop={pop} setPop={setPop} />}></Route>
        <Route path='/obj2' element={<Obj2 form={form} setForm={setForm} pop={pop} setPop={setPop} />}></Route>
        <Route path='/otkaz' element={<Otkaz />}></Route>
        <Route path='/privacy' element={<Privacy />}></Route>
        <Route path='/agreement' element={<Agreement />}></Route>
        <Route path='/obj3' element={<Obj3 form={form} setForm={setForm} pop={pop} setPop={setPop} />}></Route>
        <Route path='/obj4' element={<Obj4 form={form} setForm={setForm} pop={pop} setPop={setPop} />}></Route>
        <Route path='/obj5' element={<Obj5 form={form} setForm={setForm} pop={pop} setPop={setPop} />}></Route>
        <Route path='/obj6' element={<Obj6 form={form} setForm={setForm} pop={pop} setPop={setPop} />}></Route>
        <Route path='/obj7' element={<Obj7 form={form} setForm={setForm} pop={pop} setPop={setPop} />}></Route>
        <Route path='/obj8' element={<Obj8 form={form} setForm={setForm} />}></Route>
      </Routes>
      <Footer></Footer>
    </div>
  );
}

export default App;

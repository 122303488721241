import "./style.css";

import wt from "../../1Header/img/wt.svg";
import tg from "../../1Header/img/tg.svg";
import { useState } from "react";
import logo from "./img/logo.svg";
import cist from "./img/kist.svg";
import big from "./img/big.webp";
import c1 from "./img/c.webp";
import l1 from "./img/l1.svg";
import r1 from "./img/r1.svg";
import s1 from "./img/1.webp";
import s2 from "./img/2.webp";
import s3 from "./img/3.webp";
import s4 from "./img/4.webp";
import s5 from "./img/5.webp";
import { Slider } from "infinite-react-carousel";
import mobList from "./img/mobList.svg";
import { useNavigate } from "react-router-dom";
import cist2 from "./img/kist2.svg";
import { HashLink as Link } from "react-router-hash-link";
import mobList2 from "./img/mobList2.svg";
import Nav from "../../Links/Nav";
import f1 from "./img/f1.webp";
import f2 from "./img/f2.webp";
import f3 from "./img/f3.webp";
import f4 from "./img/f4.webp";
import f5 from "./img/f5.webp";
import strel from "../img/strel.svg";
import m1 from "./img/m1.webp";
import m2 from "./img/m2.webp";
import m3 from "./img/m3.webp";
import m4 from "./img/m4.webp";
import m5 from "./img/m5.webp";
export default function Obj4({ form, setForm }) {
  const [pop, setPop] = useState(false);
  const numImg = [m1, m2, m3, m4, m5];

  const [sliderNum, setSliderNum] = useState(0);
  const Navigate = useNavigate();
  return (
    <div className="Obj1 ojo">
      <div
        className="pop"
        style={pop ? { right: "0" } : { right: "-100vw" }}
        onClick={(e) => e.target.id != "puk" && setPop(false)}
      >
        <div className="popMenu" id="puk">
          <Link to={"/#section1"}>
            <p>
              Каталог недвижимости <br /> в Аланье
            </p>
          </Link>
          <Link to={"/#section2"}>
            <p>Сравнение стоимости недвижимости в странах</p>
          </Link>
          <Link to={"/#section5"}>
            <p>Математика стоимости квартиры</p>
          </Link>
          <Link to={"/#fsection"}>
            <p>
              Сравнительный обзор <br /> Сочи vs Аланья
            </p>
          </Link>
          <Link to={"/#section3"}>
            <p>Преимущества недвижимости в Турции</p>
          </Link>
          <Link to={"/#state"}>
            <p>Прибыль от недвижимости в Турции</p>
          </Link>
          <Link to={"/#section7"}>
            <p>Вопросы и ответы</p>
          </Link>
          <Nav></Nav>
        </div>
      </div>
      <div className="topMenu">
        <img src={logo} alt="" onClick={() => Navigate("/")} />
        <p>Напишите</p>
        <a href="https://wa.me/905367243971" target="_blank" className="keks">
          <img src={wt} alt="" />
        </a>
        <a href="https://t.me/OlgaCRpro" target="_blank" className="keks">
          <img src={tg} alt="" />
        </a>
        <a href="tel:+90 536 724 39 71" className="tel">
          +90 536 724 39 71
        </a>

        <div className="burgermenu" onClick={() => setPop(!pop)}>
          <div
            className="frist1"
            style={
              pop ? { transform: "rotate(45deg)", transformOrigin: "left" } : {}
            }
          ></div>
          <div
            className="frist2"
            style={
              pop
                ? {
                    width: "2.2vw",
                    transform: "rotate(-45deg)",
                    transformOrigin: "right",
                    marginTop: "-0.5vw",
                    left: "-0.6vw",
                    position: "relative",
                  }
                : {}
            }
          ></div>
          <div className="frist3" style={pop ? { opacity: "0" } : {}}></div>
        </div>
      </div>
      {document.body.clientWidth > 640 ? (
        <div className="sliden">
          <div
            className="lefts"
            onClick={() =>
              sliderNum != 0 ? setSliderNum(sliderNum - 1) : setSliderNum(4)
            }
          >
            <img src={strel} alt="" />
          </div>
          <div
            className="rights"
            onClick={() =>
              sliderNum != 4 ? setSliderNum(sliderNum + 1) : setSliderNum(0)
            }
          >
            <img src={strel} alt="" />
          </div>
          <img src={numImg[sliderNum]} alt="" />
          <div className="slPer">
            <img
              src={f1}
              style={
                sliderNum == 0
                  ? { border: "0.3vw solid #D8C1A2", borderRadius: "1.5vw" }
                  : {}
              }
              alt=""
              onClick={() => setSliderNum(0)}
            />
            <img
              src={f2}
              style={
                sliderNum == 1
                  ? { border: "0.3vw solid #D8C1A2", borderRadius: "1.5vw" }
                  : {}
              }
              alt=""
              onClick={() => setSliderNum(1)}
            />
            <img
              src={f3}
              style={
                sliderNum == 2
                  ? { border: "0.3vw solid #D8C1A2", borderRadius: "1.5vw" }
                  : {}
              }
              onClick={() => setSliderNum(2)}
              alt=""
            />
            <img
              style={
                sliderNum == 3
                  ? { border: "0.3vw solid #D8C1A2", borderRadius: "1.5vw" }
                  : {}
              }
              src={f4}
              onClick={() => setSliderNum(3)}
              alt=""
            />
            <img
              style={
                sliderNum == 4
                  ? { border: "0.3vw solid #D8C1A2", borderRadius: "1.5vw" }
                  : {}
              }
              src={f5}
              onClick={() => setSliderNum(4)}
              alt=""
            />
          </div>
        </div>
      ) : (
        <div className="objjSlider">
          <Slider dots arrows={false}>
            <img src={s1} alt="" />
            <img src={s2} alt="" />
            <img src={s3} alt="" />
            <img src={s4} alt="" />
            <img src={s5} alt="" />
          </Slider>
        </div>
      )}
      <div className="button" onClick={() => setForm(true)}>
        получить предложение
      </div>
      <h3>АЛАНЬЯ, КЕСТЕЛЬ</h3>
      {document.body.clientWidth > 640 ? (
        <img src={cist} className="bigO" alt="" />
      ) : (
        <img src={mobList} className="bigO" />
      )}
      {document.body.clientWidth > 640 ? (
        <img src={cist2} className="bigO" alt="" />
      ) : (
        <img src={mobList2} className="bigO" />
      )}
      <h4>Виды планировок</h4>
      {document.body.clientWidth > 640 ? (
        <div className="ahaha">
          <img src={l1} alt="" />
          <img src={c1} alt="" />
          <img src={r1} alt="" />
        </div>
      ) : (
        <div className="ahaha">
          <div>
            <img src={c1} alt="" />
          </div>
          <div className="kukus">
            <img src={l1} alt="" />
            <img src={r1} alt="" />
          </div>
        </div>
      )}
      <div className="button" onClick={() => setForm(true)}>
        получить предложение
      </div>
    </div>
  );
}

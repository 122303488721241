import Alania from '../Components/10Alania';
import Header from '../Components/1Header';
import House from '../Components/7Dom';
import Infr from '../Components/3Infr';
import Live from '../Components/2Live';
import Obzor from '../Components/6Obzor';
import OneByOne from '../Components/9onebyone';
import Portfolio from '../Components/portfolio';
import Price from '../Components/4Price';
import Question from '../Components/12Question';
import Stat from '../Components/8State';
import Summ from '../Components/5Summ';
import Support from '../Components/11Support';
import { memo } from 'react';

const Landing = memo(function Landing({ form, setForm, pop, setPop }) {
  return (
    <div>
      <Header form={form} setForm={setForm} pop={pop} setPop={setPop}></Header>
      <Portfolio></Portfolio>
      <Live form={form} setForm={setForm}></Live>

      <Infr form={form} setForm={setForm}></Infr>
      {/* <Price form={form} setForm={setForm}></Price> */}
      {/* <Summ form={form} setForm={setForm}></Summ> */}
      {/* <OneByOne form={form} setForm={setForm}></OneByOne> */}
      {/* <Obzor form={form} setForm={setForm}></Obzor> */}
      {/* <House form={form} setForm={setForm}></House> */}
      <Stat form={form} setForm={setForm}></Stat>

      {/* <Alania form={form} setForm={setForm}></Alania> */}
      {/* <Support form={form} setForm={setForm}></Support> */}
      <Question></Question>
    </div>
  );
});
export default Landing;

import { useNavigate } from "react-router-dom";
import f1 from "./img/f1.webp";
import f2 from "./img/f2.webp";
import f3 from "./img/f3.webp";
import f4 from "./img/f4.webp";
import f5 from "./img/f5.webp";
import f6 from "./img/f6.webp";
import f7 from "./img/f7.webp";
import f8 from "./img/f8.webp";
import "./style.css";
import { Slider } from "infinite-react-carousel";
export default function Portfolio() {
  const Navigate = useNavigate();
  return (
    <div className="prtic" id="section1">
      <div id="section4"></div>
      {document.body.clientWidth < 640 ? (
        <div className="sliderApp">
          <Slider dots arrows={false} duration={80}>
            <div className="sliderAppEm" onClick={() => Navigate("/obj4")}>
              <img src={f4} alt="" />

              <div className="mart">
                <h4>Аланья, Кестель</h4>
                <ul>
                  <li>
                    <div>♦️</div>
                    <p>Площадь: 4+1 230 М2 и 2+1 117 М2 </p>
                  </li>
                  <li>
                    <div>♦️</div>
                    <p>Премиум на первой береговой линии</p>
                  </li>
                </ul>
                <div className="sliderPrice">
                  <p>Цена: от 460 000 €</p>
                  <div className="button">Подробнее</div>
                </div>
              </div>
            </div>

            <div className="sliderAppEm" onClick={() => Navigate("/obj2")}>
              <img src={f2} alt="" />

              <div className="mart">
                <h4>Аланья, Центр</h4>
                <ul>
                  <li>
                    <div>♦️</div>
                    <p>До моря всего 30 метров </p>
                  </li>
                  <li>
                    <div>♦️</div>
                    <p>Площадь: 1+1 50м2</p>
                  </li>
                </ul>
                <div className="sliderPrice">
                  <p>Цена: от 210 000 €</p>
                  <div className="button">Подробнее</div>
                </div>
              </div>
            </div>
            <div className="sliderAppEm" onClick={() => Navigate("/obj3")}>
              <img src={f3} alt="" />

              <div className="mart">
                <h4>Аланья, район Оба</h4>
                <ul>
                  <li>
                    <div>♦️</div>
                    <p>Площадь: 2+1 Duplex, 95м2</p>
                  </li>
                  <li>
                    <div>♦️</div>
                    <p>Пляж Клеопатра в 500 метрах</p>
                  </li>
                </ul>
                <div className="sliderPrice">
                  <p>Цена: 200 000 €</p>
                  <div className="button">Подробнее</div>
                </div>
              </div>
            </div>
            <div className="sliderAppEm" onClick={() => Navigate("/obj1")}>
              <img src={f1} alt="" />

              <div className="mart">
                <h4>АЛАНЬЯ, ЦЕНТР</h4>
                <ul>
                  <li>
                    <div>♦️</div>
                    <p>500 м до пляжа Клеопатры </p>
                  </li>
                  <li>
                    <div>♦️</div>
                    <p>Площадь: 1+1 56,8 м2</p>
                  </li>
                </ul>
                <div className="sliderPrice">
                  <p>Цена: 199 500 €</p>
                  <div className="button">Подробнее</div>
                </div>
              </div>
            </div>
            <div className="sliderAppEm" onClick={() => Navigate("/obj5")}>
              <img src={f5} alt="" />

              <div className="mart">
                <h4>Аланья, инджекум</h4>
                <ul>
                  <li>
                    <div>♦️</div>
                    <p>Комплекс в концепции отеля 5 звезд </p>
                  </li>
                  <li>
                    <div>♦️</div>
                    <p>Площадь: 1+1 57м2 и 2+1 93м2</p>
                  </li>
                </ul>
                <div className="sliderPrice">
                  <p>Цена: от 105 000 €</p>
                  <div className="button">Подробнее</div>
                </div>
              </div>
            </div>
            <div className="sliderAppEm" onClick={() => Navigate("/obj8")}>
              <img src={f8} alt="" />

              <div className="mart">
                <h4>авсаллар</h4>
                <ul>
                  <li>
                    <div>♦️</div>
                    <p>Площадь: 1+1 50 м2 и 2+1 100 м2 </p>
                  </li>
                  <li>
                    <div>♦️</div>
                    <p>Близостью к центру Аланьи</p>
                  </li>
                </ul>
                <div className="sliderPrice">
                  <p>Цена: от 95 500 €</p>
                  <div className="button">Подробнее</div>
                </div>
              </div>
            </div>
            <div className="sliderAppEm" onClick={() => Navigate("/obj7")}>
              <img src={f7} alt="" />

              <div className="mart">
                <h4>Окурджалар</h4>
                <ul>
                  <li>
                    <div>♦️</div>
                    <p>Площадь: 1+1 40 м2 </p>
                  </li>
                  <li>
                    <div>♦️</div>
                    <p>950 метров от пляжа Окурджалар</p>
                  </li>
                </ul>
                <div className="sliderPrice">
                  <p>Цена: 94 000 €</p>
                  <div className="button">Подробнее</div>
                </div>
              </div>
            </div>
            <div className="sliderAppEm" onClick={() => Navigate("/obj6")}>
              <img src={f6} alt="" />

              <div className="mart">
                <h4>Махмутлар</h4>
                <ul>
                  <li>
                    <div>♦️</div>
                    <p>Площадь: от 28 М2 до 200м2</p>
                  </li>
                  <li>
                    <div>♦️</div>
                    <p>Рядом: торговый центр, пляж и центр.</p>
                  </li>
                </ul>
                <div className="sliderPrice">
                  <p>Цена: от 59 000 €</p>
                  <div className="button">Подробнее</div>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      ) : (
        <div className="sliderApp">
          <div className="sliderAppEm" onClick={() => Navigate("/obj4")}>
            <img src={f4} alt="" />

            <div className="mart">
              <h4>Аланья, Кестель</h4>
              <ul>
                <li>
                  <div>♦️</div>
                  <p>Площадь: 4+1 230 М2 и 2+1 117 М2 </p>
                </li>
                <li>
                  <div>♦️</div>
                  <p>Премиум на первой береговой линии</p>
                </li>
              </ul>
              <div className="sliderPrice">
                <p>Цена: от 460 000 €</p>
                <div className="button">Подробнее</div>
              </div>
            </div>
          </div>

          <div className="sliderAppEm" onClick={() => Navigate("/obj2")}>
            <img src={f2} alt="" />

            <div className="mart">
              <h4>Аланья, Центр</h4>
              <ul>
                <li>
                  <div>♦️</div>
                  <p>До моря всего 30 метров </p>
                </li>
                <li>
                  <div>♦️</div>
                  <p>Площадь: 1+1 50м2</p>
                </li>
              </ul>
              <div className="sliderPrice">
                <p>Цена: от 210 000 €</p>
                <div className="button">Подробнее</div>
              </div>
            </div>
          </div>
          <div className="sliderAppEm" onClick={() => Navigate("/obj3")}>
            <img src={f3} alt="" />

            <div className="mart">
              <h4>Аланья, район Оба</h4>
              <ul>
                <li>
                  <div>♦️</div>
                  <p>Площадь: 2+1 Duplex, 95м2</p>
                </li>
                <li>
                  <div>♦️</div>
                  <p>Пляж Клеопатра в 500 метрах</p>
                </li>
              </ul>
              <div className="sliderPrice">
                <p>Цена: 200 000 €</p>
                <div className="button">Подробнее</div>
              </div>
            </div>
          </div>
          <div className="sliderAppEm" onClick={() => Navigate("/obj1")}>
            <img src={f1} alt="" />

            <div className="mart">
              <h4>АЛАНЬЯ, ЦЕНТР</h4>
              <ul>
                <li>
                  <div>♦️</div>
                  <p>500 м до пляжа Клеопатры </p>
                </li>
                <li>
                  <div>♦️</div>
                  <p>Площадь: 1+1 56,8 м2</p>
                </li>
              </ul>
              <div className="sliderPrice">
                <p>Цена: 199 500 €</p>
                <div className="button">Подробнее</div>
              </div>
            </div>
          </div>
          <div className="sliderAppEm" onClick={() => Navigate("/obj5")}>
            <img src={f5} alt="" />

            <div className="mart">
              <h4>Аланья, инджекум</h4>
              <ul>
                <li>
                  <div>♦️</div>
                  <p>Комплекс в концепции отеля 5 звезд </p>
                </li>
                <li>
                  <div>♦️</div>
                  <p>Площадь: 1+1 57м2 и 2+1 93м2</p>
                </li>
              </ul>
              <div className="sliderPrice">
                <p>Цена: от 105 000 €</p>
                <div className="button">Подробнее</div>
              </div>
            </div>
          </div>
          <div className="sliderAppEm" onClick={() => Navigate("/obj8")}>
            <img src={f8} alt="" />

            <div className="mart">
              <h4>авсаллар</h4>
              <ul>
                <li>
                  <div>♦️</div>
                  <p>Площадь: 1+1 50 м2 и 2+1 100 м2 </p>
                </li>
                <li>
                  <div>♦️</div>
                  <p>Близостью к центру Аланьи</p>
                </li>
              </ul>
              <div className="sliderPrice">
                <p>Цена: от 95 500 €</p>
                <div className="button">Подробнее</div>
              </div>
            </div>
          </div>
          <div className="sliderAppEm" onClick={() => Navigate("/obj7")}>
            <img src={f7} alt="" />

            <div className="mart">
              <h4>Окурджалар</h4>
              <ul>
                <li>
                  <div>♦️</div>
                  <p>Площадь: 1+1 40 м2 </p>
                </li>
                <li>
                  <div>♦️</div>
                  <p>950 метров от пляжа Окурджалар</p>
                </li>
              </ul>
              <div className="sliderPrice">
                <p>Цена: 94 000 €</p>
                <div className="button">Подробнее</div>
              </div>
            </div>
          </div>
          <div className="sliderAppEm" onClick={() => Navigate("/obj6")}>
            <img src={f6} alt="" />

            <div className="mart">
              <h4>Махмутлар</h4>
              <ul>
                <li>
                  <div>♦️</div>
                  <p>Площадь: от 28 М2 до 200м2</p>
                </li>
                <li>
                  <div>♦️</div>
                  <p>Рядом: торговый центр, пляж и центр.</p>
                </li>
              </ul>
              <div className="sliderPrice">
                <p>Цена: от 59 000 €</p>
                <div className="button">Подробнее</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
